import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'usehooks-ts';
import useActivityCategories from '../../hooks/useActivityCategories';
import useAppLanguage from '../../hooks/useAppLanguage';
import useAppSelector from '../../hooks/useAppSelector/useAppSelector';
import useAppUserMetadata from '../../hooks/useAppUserMetadata';
import useGroupData from '../../hooks/useContext/useGroupData/useGroupData';
import useMultiProfileFlag from '../../hooks/useMultiProfileFlag';
import useUserActivityScopes from '../../hooks/useUserActivityScopes';
import useUserTaskScopes from '../../hooks/useUserTaskScopes';
import { UserState } from '../../models/user';
import PageLoading from '../../pages/PageLoading';

interface UserInfoProviderProps {
  children?: JSX.Element;
}

const UserInfoProvider = ({ children }: UserInfoProviderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const isFetching = useIsFetching();
  const queryClient = useQueryClient();

  useEffectOnce(() => {
    setTimeout(() => {
      if (isFetching > 0) {
        queryClient.invalidateQueries();
      }
    }, 10000);
  });

  const { isSuccessMetadata, hasErrorMetadata, vendorGroupId, metadata } = useAppUserMetadata();
  const { isSuccessMultiProfileFlag, isMultiProfileLoading } = useMultiProfileFlag();
  const preferredLanguage = metadata?.profile?.preferredLanguage;
  useAppLanguage({ preferredLanguage });
  const { isLoading: isLoadingUserActivityScopes } = useUserActivityScopes();
  const { isSuccess: isSuccessUserTaskScopes } = useUserTaskScopes();
  const { isSuccess: isSuccessActivityCategories } = useActivityCategories();
  const { availableProfiles } = useAppSelector<UserState>((state) => state.user);

  const { handleChangeUserGroupInfo } = useGroupData();

  const doesNotHaveVendorGroupId = isSuccessMetadata && !vendorGroupId;
  const doesNotHaveProfile = !!availableProfiles && availableProfiles?.length === 0;

  const isUserInfoReady =
    isSuccessMetadata &&
    isSuccessMultiProfileFlag &&
    !isLoadingUserActivityScopes &&
    isSuccessUserTaskScopes &&
    isSuccessActivityCategories &&
    !isMultiProfileLoading;

  const hasErrorUserInfo = hasErrorMetadata || doesNotHaveVendorGroupId || doesNotHaveProfile;

  const render = useMemo(() => {
    if (!isUserInfoReady && !hasErrorUserInfo) {
      handleChangeUserGroupInfo({ error: '' });
      return <PageLoading />;
    } else if (hasErrorUserInfo && hasErrorUserInfo) {
      let errorMessageId = 'message.error.requestUserInfo';

      if (doesNotHaveVendorGroupId) {
        errorMessageId = 'message.error.userWithoutVendorId';
      } else if (doesNotHaveProfile) {
        errorMessageId = 'message.error.userWithoutProfile';
      }

      handleChangeUserGroupInfo({ error: formatMessage({ id: errorMessageId }) });
    }

    return children;
  }, [
    children,
    doesNotHaveProfile,
    doesNotHaveVendorGroupId,
    formatMessage,
    handleChangeUserGroupInfo,
    hasErrorUserInfo,
    isUserInfoReady,
  ]);

  return render;
};

export default UserInfoProvider;
