import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { getActivityTypes } from '../../api/services/frontline';
import { QUERY_KEY_ACTIVITY_TYPES } from '../../consts/query';
import { ActivityState, ActivityType, ActivityTypeList, ListFilters } from '../../models/activity';
import {
  resetActivityTypes,
  setAvailableActivityTypes,
  setInitialListFilters,
  setListFilters
} from '../../redux/slices/activitySlice';
import useAppDispatch from '../useAppDispatch';
import useAppSelector from '../useAppSelector';
import useGroupData from '../useContext/useGroupData/useGroupData';

function useUserActivityScopes(): {
  isSuccess: boolean;
  isLoading: boolean;
} {
  const { availableActivityTypes } = useAppSelector<ActivityState>((state) => state.activity);
  const dispatch = useAppDispatch();

  const { groupData } = useGroupData();
  const { vendorGroupId } = groupData;

  const {
    data: dataActivityTypes,
    isLoading: isLoadingActivityTypes,
    isError,
  } = useQuery<AxiosResponse<ActivityTypeList>, AxiosError>(
    [QUERY_KEY_ACTIVITY_TYPES, vendorGroupId],
    ({ signal }) => getActivityTypes({ signal }),
    {
      enabled: !!vendorGroupId,
    }
  );

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const listFilters: ListFilters = {
      isSponsored: false,
    };

    dispatch(setInitialListFilters(listFilters));
    dispatch(setListFilters(listFilters));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      setIsSuccess(true);
    }
  }, [isError]);

  useEffect(() => {
    if (dataActivityTypes && !isSuccess) {
      const activityTypes = dataActivityTypes?.data?.content;

      const types: string[] = [];

      activityTypes.forEach((type) => {
        types.push(type.name.toUpperCase());
      });

      const activityTypesAvailable: ActivityType[] = cloneDeep(
        availableActivityTypes.filter((type) => types.includes(type.name.toUpperCase()))
      );

      activityTypesAvailable.forEach((type) => {
        const activityType = activityTypes.find(
          (element) => element.name.toUpperCase() === type.name.toUpperCase()
        );
        type.activityTypeId = activityType.activityTypeId;
        type.title = activityType.title;
      });

      dispatch(setAvailableActivityTypes(activityTypesAvailable));
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataActivityTypes, isSuccess]);

  useEffect(() => {
    if (vendorGroupId) {
      dispatch(resetActivityTypes());
      setIsSuccess(false);
    }
  }, [dispatch, vendorGroupId]);

  return {
    isSuccess,
    isLoading: isLoadingActivityTypes,
  };
}

export default useUserActivityScopes;
