import { Frequency } from './frequency';
import { ConfigTaskLocalModel } from './task';
import { ProfileId } from './user';

export enum FeatureId {
  SimpleCoverage = 'tmth_qty_coverage_skus',
}
export enum CoverageFeatureId {
  SimpleCoverage = 'l60d_qty_coverage_skus',
}
export enum FeatureType {
  SimpleCoverage = 'coverage',
  Combo = 'combo',
  Challenge = 'challenge',
}

export enum FeatureMultiselect {
  Combo = 'COMBO',
  Challenge = 'CHALLENGE',
}

export enum AudienceType {
  Static = 'STATIC',
  Dynamic = 'DYNAMIC',
}

export interface GetFeatureMultiselectMetadata {
  signal?: AbortSignal;
  featureType: 'COMBO' | 'CHALLENGE';
  page: number;
  metadataId?: string;
}

export interface FeatureMultiselectMetadada {
  id: string;
  metadataId: string;
  vendorGroupId: string;
  country: string;
  type: 'COMBO' | 'CHALLENGE';
  deleted: boolean;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface GetFeaturesMultiselectMetadataResponse {
  content: FeatureMultiselectMetadada[];
  pagination: Pagination;
}

export interface ActivityList {
  content: Activity[];
  pagination: Pagination;
}

export interface LocalModel {
  active: boolean;
  country: string;
  endDate: string;
  id: string;
  modelId: string;
  startDate: string;
  vendorGroupId: string;
}
export interface LocalModelList {
  content: LocalModel[];
  pagination: Pagination;
}
export interface GetLocalModels {
  signal?: AbortSignal;
  active?: boolean;
  page?: number;
  pageSize?: number;
  country?: string;
  modelId?: string;
}
export interface GetSubtypes {
  signal?: AbortSignal;
  page?: number;
  pageSize?: number;
  country?: string;
  vendorGroupId: string;
}
export interface GetSimpleCoverageProductsList {
  body: PayloadSimpleCoverageProductsList;
  country: string;
  xAppName: string;
  originSystem: string;
  acceptLanguage: string;
}
export interface PayloadSimpleCoverageProductsList {
  vendorIds: string[];
  query: string;
}

export interface Activity {
  activityTemplateId: string;
  name: string;
  priority: number;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  durationInMinutes: number;
  activityTemplateCategory: ActivityCategory;
  activitySubtype?: ActivitySubtype;
  tags: string[];
  isSponsored: boolean;
  sponsoredBy: string;
  sponsoredById: string;
  campaignId?: string;
  frequency: Frequency;
  activityType: ActivityType;
  activityParams?: { key?: string };
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple;
  createAt: string;
  updateAt: string;
  updateBy: string;
  isCategoryValid?: boolean;
  vendorId?: string;
  visionRuleId?: string;
  category: CategoryName;
  activityTemplateGroupId?: string;
  localModel?: ConfigTaskLocalModel;
}

export interface ActivitySurvey {
  activityTemplateId: string;
  name: string;
  priority: number;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  durationInMinutes: number;
  activityTemplateCategory: ActivityCategory;
  activitySubtype?: ActivitySubtype;
  tags: string[];
  isSponsored: boolean;
  sponsoredBy: string;
  sponsoredById: string;
  campaignId?: string;
  frequency: Frequency;
  activityType: ActivityType;
  activityParams?: { key?: string };
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple;
  createAt: string;
  updateAt: string;
  updateBy: string;
  isCategoryValid?: boolean;
  vendorId?: string;
  survey: Survey[];
  activityTemplateGroupId?: string;
}

export interface ActivityPhoto {
  activityTemplateId: string;
  isActive: boolean;
  durationInMinutes: number;
  frequency: Frequency;
  tags: string[];
  activityType: ActivityType;

  templateTitle: string;
  name: string;
  priority: number | null;
  categoryId: string;
  vendorId: string;
  updatedAt: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
  imageRecognitionBucket: ImageRecognitionBucketId;
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  taskType: TaskType;
  // configTaskEffectiveness: {
  //   subType: SubTypeIdPhoto;
  //   visionConfigId: string;
  // };
  activityTemplateCategory: ActivityCategory;
  visionRuleId: string;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  activityTemplateGroupId?: string;
}

export interface CreateActivitySimple {
  body: PayloadActivitySimple;
}

export interface CreateActivitySurvey {
  body: PayloadActivitySurvey;
  vendorGroupId?: string;
}

export interface CreateActivityPhoto {
  body: PayloadActivityPhoto;
}
export interface PayloadActivitySurvey extends PayloadActivitySimple {
  requiredDuration: any;
  durationInMinutes?: number;
  frequency?: string;
  survey: Survey[];
}

export interface UpdateActivitySurvey {
  activityId: string;
  body: PayloadActivitySurvey;
}
export interface UpdateActivityPhoto {
  activityId: string;
  body: PayloadActivityPhoto;
}

export interface Survey {
  id: string;
  title: string;
  isRequired: boolean;
  responseType: SurveyQuestionResponseId;
  options?: SubTasksOptions[];
}
export interface SubTasksOptions {
  id: string;
  value: string;
  jump?: SubTaskOptionsConditional;
}
export interface SubTaskOptionsConditional {
  position?: number;
  id?: string;
}

export interface ActivityConfigurationPerProfile {
  profileId: ProfileId;
  message: string;
  validEffectiveness?: string;
  invalidEffectiveness?: string;
  pendingEffectiveness?: string;
}

export interface ActivityProfileList {
  content: ActivityProfile[];
  pagination: Pagination;
}

export interface ActivityProfile {
  profileId: string;
  name: string;
  title?: string;
}

export interface PayloadActivitySimple {
  name: string;
  activityTemplateCategoryId?: string;
  isSponsored?: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  activityConfigurationPerProfile?: ActivityConfigurationPerProfile[];
  activitySubtypeId?: string;
  durationInMinutes?: number;
  frequencyId?: string;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  audienceTemplate?: ConfigActivityAudience[] | any;
  subTasks?: Survey[];
  activityTypeId?: string;
  tags?: string[];
  campaignId?: string;
  activityTemplateGroupId: string;
  activityType?: string;
  audienceType: string;
}

export interface PayloadActivityPhoto extends PayloadActivitySimple {
  imageRecognitionBucket?: ImageRecognitionBucketId;
  configTaskEffectiveness?: ConfigTaskEffectivenessPhoto;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  visionRuleId: string;
}

export interface CreateActivitySimpleResponse {
  id: string;
}

export interface UpdateActivityResponse {
  id: string;
}

export interface ConfigActivityAudience {
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: any;
  period?: any;
  minValue?: any;
  maxValue?: any;
  requiredPeriod?: any;
}

export interface ConfigActivityEffectivenessSimple {
  featureType: FeatureType;
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: string | number | boolean | string[];
  period?: any;
  minValue?: any;
  maxValue?: any;
  requiredPeriod?: any;
}

export interface ConfigActivityDynamicAudienceTemplate {
  audienceType: string;
  rule: {
    feature?: string;
    operator?: string;
    value?: string;
    logicalOperator?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
    arguments?: ConfigActivityAudience | ConfigActivityAudience[];
  };
}

export interface ActivityState {
  availableActivityTypes?: ActivityType[];
  availableActivityCategories?: ActivityCategory[];
  availableSubTypes?: SubType[];
  initialListFilters?: ListFilters;
  listFilters?: ListFilters;
  featureList?: Feature[];
  featureMultiselectMetadata?: FeatureMultiselectMetadada[];
  groupedFeatureList?: Feature[];
  availableImageRecognitionBuckets?: ImageRecognitionBucketId[];
  availableVisionConfigs?: string[];
  availableImageRecognitionBucketsDeprecated: ImageRecognitionBucket[];
}

export interface FeatureList {
  content: Feature[];
  pagination: Pagination;
}

export interface Feature {
  values?: string[];
  prefixName?: string;
  activitySubtypeId?: string;
  domain: string;
  enabled?: boolean;
  featureId?: string;
  featurePrefix?: {
    featurePrefixId: string;
    name: string;
    title: string;
  };
  featureType?: string;
  featureValue?: {
    value?: string[];
  };
  isAudience?: boolean;
  isEffectiveness?: boolean;
  name: string;
  namePrefix?: string;
  operators?: string[];
  params?: {
    minimumValue?: number;
    maximumValue?: number;
  };
  source?: string;
  title?: string;
  suffix?: {
    name: string;
    title?: string;
  }[];
}

export interface OptDataFeature {
  data: DataFeature[];
}

export interface DataFeature {
  name?: string;
  isAudience?: boolean;
  isValidation?: boolean;
  allowed_values?: string[];
}
export interface ImageRecognitionBucket {
  id: ImageRecognitionBucketId;
  categories?: CategoryName[];
}

export interface ActivityTypeList {
  content: ActivityType[];
  pagination: Pagination;
}

export interface ActivityType {
  activityTypeId?: string;
  name?: ActivityTypeName;
  icon?: string;
  profiles?: string[];
  hasPermission?: boolean;
  title?: string;
}

export interface ActivityCategoryList {
  content: ActivityCategory[];
  pagination: Pagination;
}

export interface ActivityCategory {
  id?: string;
  activityTemplateCategoryId?: string;
  category: string;
  title?: string;
}

export interface ActivitySubtypeList {
  content: ActivitySubtype[];
  pagination: Pagination;
}

export interface ActivitySubtype {
  activitySubtypeId: string;
  name: string;
  title: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface ListFilters {
  isSponsored?: boolean;
}

export interface SubType {
  id: SubTypeName;
  addMethods: AddMethod[];
  effectivenessFormField: EffectivenessSimpleFormField;
}

export interface Type {
  id: ActivityTypeName;
  icon: string;
  profiles: ProfileId[];
  hasPermission: boolean;
}

export enum TaskTypeId {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export interface TaskType {
  id: TaskTypeId;
  icon: string;
  profiles: string[];
  categories: CategoryName[];
  hasPermission: boolean;
}

export enum SubTypeIdPhoto {
  ImageRecognition = 'IMAGE_RECOGNITION',
}

export enum EffectivenessPhotoFormField {
  SubType = 'subType',
  VisionConfigId = 'visionConfigId',
}

export interface ConfigTaskEffectivenessPhoto {
  subType: SubTypeIdPhoto;
  visionConfigId: string;
}

export enum ActivityFormFieldPhoto {
  ImageRecognitionBucket = 'imageRecognitionBucket',
  ConfigEffectiveness = 'configTaskEffectiveness',
}

export enum ImageRecognitionBucketId {
  Shelf = 'SHELF',
  Poster = 'POSTER',
  Cooler = 'COOLER',
  None = 'NONE',
  Empty = '',
}

export enum ActivityTypeName {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export enum SubTypeName {
  Challenge = 'CHALLENGE',
  Combo = 'COMBO',
  Coverage = 'MONTHLY_PRODUCT_COVERAGE',
  Volume = 'VOLUME',
  Empty = '',
}

export enum AddMethod {
  Manual = 'MANUAL',
  Csv = 'CSV',
}

export enum EffectivenessSimpleFormField {
  Condition = 'condition',
  Feature = 'feature',
  Operator = 'operator',
  Value = 'value',
  Period = 'period',
  MinValue = 'minimumValue',
  MaxValue = 'maximumValue',
  RequiredPeriod = 'requiredPeriod',
  Skus = 'skus',
  MetadataIds = 'metadataIds',
  FeatureType = 'featureType',
}

export enum CategoryName {
  CustomerSatisfaction = 'CUSTOMER_SATISFACTION',
  DigitalEducation = 'DIGITAL_EDUCATION',
  Execution = 'EXECUTION',
  ValueCreation = 'VALUE_CREATION',
  PortfolioExpansion = 'PORTFOLIO_EXPANSION',
  VolumeRevenue = 'VOLUME_REVENUE',
  MarketplacePortfolioExpansion = 'MARKETPLACE_PORTFOLIO_EXPANSION',
  MarketplaceRevenue = 'MARKETPLACE_REVENUE',
  ExecutionAbi = 'EXECUTION_ABI',
  ExecutionMarketplace = 'EXECUTION_MARKETPLACE',
  MiNegocio = 'MI_NEGOCIO',
  FinTech = 'FINTECH',
  ChallengeAndRewards = 'CHALLENGE_REWARDS',
  OrderOnBEES = 'ORDER_BEES',
  DTaaS = 'DTAAS',
  SurveyDataCollection = 'SURVEY_DATA_COLLECTION',
  OtherTasks = 'OTHER_TASKS',
  Empty = '',
}

export enum ActivityFormField {
  ActivityTemplateId = 'activityTemplateId',
  Name = 'name',
  ProfileId = 'profileId',
  Profile = 'profile',
  IsSponsored = 'isSponsored',
  SponsoredBy = 'sponsoredBy',
  SponsoredById = 'sponsoredById',
  DurationInMinutes = 'durationInMinutes',
  FrequencyId = 'frequencyId',
  ActivityTemplateCategoryId = 'activityTemplateCategoryId',
  AudienceTemplate = 'audienceTemplate',
  EffectivenessTemplate = 'effectivenessTemplate',
  ActivityConfigurationPerProfile = 'activityConfigurationPerProfile',
  Message = 'message',
  ValidEffectiveness = 'validEffectiveness',
  InvalidEffectiveness = 'invalidEffectiveness',
  PendingEffectiveness = 'pendingEffectiveness',
  ActivityTypeId = 'activityTypeId',
  ActivitySubtypeId = 'activitySubtypeId',
  Tags = 'tags',
  CampaignId = 'campaignId',
  Priority = 'priority',
  Survey = 'survey',
  VisionRuleId = 'visionRuleId',
  Category = 'category',
  RequiredDuration = 'requiredDuration',
  ActivityTemplateGroupId = 'activityTemplateGroupId',
  AudienceType = 'audienceType',
}

export enum DurationId {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Long = 'LONG',
  VeryLong = 'VERY_LONG',
  VeryFast = 'VERY_FAST',
  Empty = '',
}

export enum FormTypeId {
  Create = 'CREATE',
  Update = 'UPDATE',
  Duplicate = 'DUPLICATE',
}

export enum FrequencyId {
  EveryVisit = 'EVERY_VISIT',
  Monthly = 'MONTHLY',
  Effective = 'EVERY_VISIT_UNTIL_COMPLETED_OR_EFFECTIVE',
  Empty = '',
}

export enum SurveyQuestionResponseId {
  SingleSelect = 'SINGLESELECTION',
  FreeText = 'FREETEXT',
  Conditional = 'CONDITIONAL',
  Numeric = 'NUMERIC',
  MultipleSelect = 'MULTIPLESELECTION',
  Empty = '',
}

export interface ActivityRecommendedUpdate
  extends Omit<
    Activity,
    'activityConfigurationPerProfile' | 'effectivenessTemplate' | 'audienceTemplate'
  > {
  recommendedUpdateId: string;
  recommendedUpdateType: RecommendedUpdateType;
  recommendedUpdateReviewStatus?: RecommendedUpdateReviewStatus;
  isGlobal: boolean;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile[];
  audienceTemplate?: {
    audienceTemplateId: string;
    rule: {
      value: number;
      feature: string;
      operator: string;
      featureType: string;
    };
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
  };
  effectivenessTemplate?: {
    effectivenessTemplateId: string;
    rule: {
      value: number;
      feature: string;
      operator: string;
      featureType: string;
    };
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
  };
}

export enum RecommendedUpdateReviewStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum RecommendedUpdateType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}
