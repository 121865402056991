import { useAuthenticationService } from 'admin-portal-shared-services';
import { AxiosResponse } from 'axios';
import {
  ENDPOINT_ACTIVITIES,
  ENDPOINT_CREATE_TASK_PHOTO,
  ENDPOINT_CREATE_TASK_SURVEY,
  ENDPOINT_DELETE_ACTIVITY_TEMPLATE,
  ENDPOINT_GET_ACTIVITY,
  ENDPOINT_GET_ACTIVITY_FEATURES,
  ENDPOINT_GET_ACTIVITY_PROFILES,
  ENDPOINT_GET_ACTIVITY_TYPES,
  ENDPOINT_GET_CATEGORIES,
  ENDPOINT_GET_FEATURE_MULTI_SELECT_METADATA,
  ENDPOINT_GET_FREQUENCIES,
  ENDPOINT_GET_SPONSORS,
  ENDPOINT_SET_ACTIVITY_PRIORITY,
  ENDPOINT_SET_ACTIVITY_PRIORITY_SYNC,
  SERVICE_FRONTLINE_METADATA_CONFIGURATION,
  getServiceDomain,
} from '../../../consts/endpoint';
import {
  CreateActivitySurvey,
  GetFeatureMultiselectMetadata,
  GetFeaturesMultiselectMetadataResponse,
} from '../../../models/activity';
import { GetFrequency } from '../../../models/frequency';
import {
  CreateActivityPhoto,
  CreateActivitySimple,
  CreateActivitySimpleResponse,
  UpdateActivityResponse,
} from '../../../models/task';
import { isClusterEuUser } from '../../../utils/validations';
import api from '../../api';

// eslint-disable-next-line react-hooks/rules-of-hooks
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
const isClusterEu = isClusterEuUser(userCountry);

export const getFrequencies = async ({ signal }: GetFrequency): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_FREQUENCIES}`,
    config: {
      signal,
    },
  });

export const createActivity = async ({
  body,
  vendorGroupId,
}: CreateActivitySimple): Promise<AxiosResponse<CreateActivitySimpleResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_ACTIVITIES}`,
    body,
    config: {
      headers: {
        vendorGroupId,
      },
    },
  });

export const createActivitySurvey = async ({
  body,
  vendorGroupId,
}: CreateActivitySurvey): Promise<AxiosResponse<UpdateActivityResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_CREATE_TASK_SURVEY}`,
    body,
    config: {
      headers: {
        vendorGroupId,
      },
    },
  });

export const createActivityPhoto = async ({
  body,
}: CreateActivityPhoto): Promise<AxiosResponse<UpdateActivityResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_CREATE_TASK_PHOTO}`,
    body,
  });

export const getCategories = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_CATEGORIES}`,
    config: {
      signal,
    },
  });

export const getActivityTypes = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY_TYPES}`,
    config: {
      signal,
    },
  });

export const getActivityFeatures = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY_FEATURES}`,
    config: {
      params: {
        pageSize: 10000,
      },
      signal,
    },
  });

export const getFeatureMultiselectMetadata = async ({
  signal,
  featureType,
  page,
  metadataId,
}: GetFeatureMultiselectMetadata): Promise<AxiosResponse<GetFeaturesMultiselectMetadataResponse>> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_FEATURE_MULTI_SELECT_METADATA}`,
    config: {
      params: {
        pageSize: 100,
        type: featureType,
        page,
        metadataId: metadataId?.toString() || null,
      },
      signal,
    },
  });

export const getActivityProfiles = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY_PROFILES}`,
    config: {
      signal,
    },
  });

export const getActivityList = async ({
  templateName,
  page,
  pageSize,
  profileId,
  activityTemplateGroupId,
  signal,
}): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_ACTIVITIES}`,
    config: {
      params: {
        templateName,
        profileId,
        page,
        pageSize,
        order: 'priority',
        activityTemplateGroupId,
      },
      signal,
    },
  });

export const getActivity = async ({ activityId, signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY({ activityId })}`,
    config: {
      params: {
        activityTemplateId: activityId,
      },
      signal,
    },
  });

export const setPriority = async ({ body, params }): Promise<AxiosResponse> =>
  api.patch({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_SET_ACTIVITY_PRIORITY}`,
    body,
    config: {
      params,
    },
  });

export const prioritySync = async ({
  profileId,
  isSponsored,
  activityTemplateGroupId,
}): Promise<AxiosResponse> =>
  api.patch({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_SET_ACTIVITY_PRIORITY_SYNC}`,
    config: {
      params: {
        activityTemplateGroupId,
      },
    },
  });

export const deleteActivityTemplate = async ({ activityTemplateId }): Promise<AxiosResponse> =>
  api.delete({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_DELETE_ACTIVITY_TEMPLATE({
      activityId: activityTemplateId,
    })}`,
    config: {
      params: {
        activityTemplateId,
      },
    },
  });

export const getSponsors = async ({ vendorGroupId, page, signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(isClusterEu)}${ENDPOINT_GET_SPONSORS}?${vendorGroupId}`,
    config: {
      signal,
      params: {
        pageSize: 500,
        page
      },
    },
  });
