import taskGroupsPtBR from '../../apps/task-groups/i18n/languages/pt-BR';
import { RecommendedUpdateReviewStatus, RecommendedUpdateType } from '../../models/activity';
import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  title: {
    pageTasks: 'Tarefas',
    filterPhotoTasks: 'Tarefas de foto',
    filterSimpleSurveyTasks: 'Tarefas padrões e questionários',
    filterSponsoredTasks: 'Tarefas Patrocinadas',
    modalSubmissionForm: 'Submeter tarefa',
    modalSendRecommendedTask: 'Enviar tarefa recomendada',
    pageTaskCreateSimple: 'Nova tarefa padrão',
    pageTaskUpdateSimple: 'Atualizar tarefa padrão',
    pageTaskDuplicateSimple: 'Duplicação de tarefa padrão',
    pageTaskCreateSurvey: 'Novo questionário',
    pageTaskUpdateSurvey: 'Atualizar questionário',
    pageTaskDuplicateSurvey: 'Duplicação de questionário',
    pageTaskCreatePhoto: 'Nova foto',
    pageTaskUpdatePhoto: 'Atualizar foto',
    pageTaskDuplicatePhoto: 'Duplicação de foto',
    pageTaskRecommendCreate: 'Criar template recomendado',
    pageTaskRecommendUpdate: 'Atualizar template recomendado',
    pageTaskRecommendDuplicate: 'Duplicação de template recomendado',
    pageTaskCancellationList: 'Solicitações de Cancelamento de Tarefa',
    pageTaskCancellationCreate: 'Abrir Solicitação de Cancelamento',
    pageTaskCancellationUpdate: 'Atualizar Solicitação de Cancelamento',
    pageNotFound: 'Página não encontrada',
    pageLoading: 'Carregando...',
    pageError: 'Algo deu errado',
    modalDeleteTask: 'Excluir esta tarefa: {taskName}?',
    modalDeleteSubmission: 'Excluir este envio: {submissionName}?',
    modalConfirmDefault: 'Confirmação',
    modalConfirmChangeEffectivenessIDAddMode: {
      toMANUAL: "Mudar para o modo 'adicionar IDs manualmente'?",
      toCSV: "Mudar para o modo 'adicionar arquivo CSV'?",
    },
    modalConfirmChangeSubCategory: 'Deseja mudar a Subcategoria?',
    mandatory: 'Obrigatória?',
    formTask: 'Detalhes da tarefa',
    effectivenessCheck: 'Condições de validação',
    criteriaBdrIds: 'BDR IDs',
    modalSaveDraft: 'Salvar como rascunho?',
    criteriaCancellationPeriod: 'Período de Cancelamento',
  },
  subtitle: {
    modalSubmissionForm: 'Defina o nome do envio e seu período',
    modalSendRecommendedTask:
      'Após serem publicadas, as tarefas são enviadas para os BDRs com base em vários fatores, incluindo tempo estimado de conclusão, tempo disponível para visita e prioridade da tarefa.',
    taskCancellationRequestCriteria: 'Selecione e preencha os critérios da solicitação',
    criteriaBdrIds: 'Adicionar BDR IDs',
    enterTaskCancellationNameReason: 'Digite o nome da solicitação e o motivo do cancelamento.',
    modalSaveDraftCancellationRequest:
      'Digite um nome de solicitação e salve para poder acessar esta solicitação posteriormente.',
    criteriaCancellationPeriod: 'Dia, mês ou período em que a tarefa permanecerá cancelada.',
    coverageItemCard: 'SKU ID: {skuId}',
  },
  table: {
    header: {
      taskSubmissions: 'Envios desta tarefa',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Vigência',
      taskCancellation: {
        name: 'Nome da solicitação',
        reason: 'Motivo',
        period: 'Período de Cancelamento',
        status: 'Status',
        actions: 'Ações',
      },
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Gestão de Tarefas',
      pageOperations: 'Painel de operações',
      pageTaskGroups: 'Grupos de Tarefas',
      pageAudienceBuilder: 'Criador de audiência',
    },
  },
  tooltip: {
    default: 'Veja essa dica',
    menu: 'Menu',
    expandTask: 'Expandir tarefa',
    noPermission: 'Não tem permissão',
    submissionActives: 'Templates com submissão ativa não podem ser deletados',
    categoryDeprecated:
      'A categoria de tarefa selecionada não está mais disponível. Selecione outra',
    localModelIdDeprecated:
      'O id de modelo local selecionado não está mais disponível. selecione outro',
    task: {
      prioritize: 'Priorize esta tarefa para enviá-la',
      globalTask: 'Use esta tag se esta tarefa estiver relacionada à Estratégia Global BEES.',
      category: {
        disabled: '{profile} não pode executar tarefas de {category}',
      },
      subCategory: {
        disabled: '{subCategory} não está disponível',
        disabledInCategory: '{subCategory} não está disponível em {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            'É necessário selecionar uma categoria antes de adicionar o critério de efetividade',
          subCategoryDisabled: '{subCategory} só está disponível em {categories}',
          addId: 'Use Enter para adicionar IDs ou Vírgula para separar valores',
          addIdManualOrCsv:
            'Use Enter para adicionar IDs ou vírgula para separar valores ou enviar um CSV. Para verificar como o arquivo é construído, baixe o modelo abaixo',
        },
      },
      sponsored: 'Tarefas patrocinadas devem ter um parceiro patrocinador definido',
      survey: {
        reorder: 'Reordenar',
      },
      photo: {
        addVisionJobIdButton: 'Digite ou cole um vision job id para prosseguir',
        visionJobIdSelected:
          'Vision job id já selecionado, para incluir outro, favor excluir o vision job id atual',
      },
    },
    submission: {
      delete: 'Cancelar envio',
      downloadCsv: 'Baixar lista de PDVs',
      csvFirst:
        'Você pode gerar a lista de AccountIDs usando seu banco de dados local ou preencher a lista manualmente com os AccountIDs em uma planilha. ',
      csvSecond:
        'Depois disso, insira o arquivo CSV aqui. Para saber como o arquivo é construído, baixe o modelo abaixo.',
      validityFirst:
        'A vigência é o período que a tarefa fica disponível para os RNs responderem/executarem.',
      validitySecond:
        'Se a tarefa precisar ter vigência de somente um dia, selecione apenas a data de início.',
    },
    priorization: {
      needChange: 'Altere a priorização para confirmar',
      confirm: 'Confirmar',
      reorder: 'Reordenar',
      cancel: 'Cancelar',
    },
    taskCancellation: {
      viewRequest: 'Ver solicitação',
      deleteRequest: 'Excluir solicitação',
    },
    taskForm: {
      controlSettings: 'Editar Perfil e Operação na página de Gerenciamento de Tarefas.',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Lembre-se:',
          message: 'Altere o título da tarefa a ser duplicada para diferenciá-la da original.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Lembre-se:',
          message: 'Essa página é apenas para visualização e os dados não podem ser alterados.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'A vigência é o período que a tarefa fica disponível para os RNs responderem/executarem. Se a tarefa precisar ter vigência de somente um dia, selecione apenas a data de início.',
        dropzoneFile:
          'Você pode gerar a lista de AccountIDs usando seu banco de dados local ou preencher a lista manualmente com os AccountIDs em uma planilha. Depois disso, insira o arquivo CSV aqui. Para saber como o arquivo é construído, baixe o modelo abaixo.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Tarefa criada com sucesso!',
        update: 'Tarefa alterada com sucesso!',
        delete: 'Tarefa deletada com sucesso!',
        priority: 'Prioridade alterada com sucesso!',
      },
      taskCancellation: {
        create: 'Nova solicitação criada com sucesso',
        update: 'Solicitação atualizada',
      },
      submission: {
        create: 'Envio criado com sucesso!',
        delete: 'Envio deletado com sucesso!',
      },
      recommendedSubmission: {
        create: 'Tarefa enviada com sucesso!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'Não é possível adicionar mais de um arquivo',
      task: {
        create: 'Ocorreu um erro durante a criação da Tarefa. Por favor, tente novamente.',
        update: 'Ocorreu um erro durante a alteração da Tarefa. Por favor, tente novamente.',
        delete: 'Ocorreu um erro durante a deleção da Tarefa. Por favor, tente novamente.',
        getTaskData:
          'Ocorreu um erro ao obter os dados da Tarefa. Por favor, tente novamente mais tarde.',
        effectivenessCheck: {
          idAlreadyAdded: 'Este ID já foi adicionado!',
        },
        priority: 'Ocorreu um erro durante a alteração da Prioridade. Por favor, tente novamente.',
        invalidFrequency: 'A frequência é inválida, selecione uma das opções disponíveis.',
        unavailableMonthlyFrequency:
          'As tarefas de cobertura nÃ£o funcionam, a frequÃªncia de 30 dias deve ser registrada no back-end para este vendorGroupID',
      },
      taskCancellation: {
        getRequestData:
          'Ocorreu um erro ao obter os dados da solicitação. Por favor, tente novamente mais tarde.',
        create: 'Ocorreu um erro ao criar a solicitação. Por favor, tente novamente mais tarde.',
        update:
          'Ocorreu um erro ao atualizar a solicitação. Por favor, tente novamente mais tarde.',
      },
      submission: {
        create: 'Ocorreu um erro ao criar o envio. Por favor, tente novamente.',
        delete: 'Ocorreu um erro ao excluir o envio. Por favor, tente novamente.',
      },
    },
    warning: {
      task: {
        requiredField:
          'Campos obrigatórios não preenchidos! Verifique o formulário antes de enviar novamente.',
      },
    },
  },
  comboBox: {
    noOptions: 'Nenhuma opção encontrada',
  },
  message: {
    desc: {
      deleteTask:
        'Se excluir, todos os envios desta tarefa serão excluídos. Esta ação não pode ser desfeita.',
      deleteSubmission:
        'Se excluir, os RNs não poderão acessar esta tarefa nos PDVs definidos para este envio.',
      modalConfirmDefault: 'Você tem certeza de que quer fazer isso?',
      modalConfirmChangeEffectivenessIDAddMode: {
        toMANUAL: 'Se o fizer, o arquivo CSV será apagado definitivamente.',
        toCSV: 'Se o fizer, todos os IDs adicionados serão apagados definitivamente.',
      },
      modalConfirmChangeSubCategory:
        'Se o fizer, as informações da Subcategoria selecionada anteriormente serão apagadas definitivamente.',
      dropzoneCsvIds: 'Solte um arquivo .CSV com os IDs ou',
      categoryPhoto: '*Para tarefas de fotos, execução é a única categoria aceita.',
      criteriaBdrIdsQuantity: '{quantity} IDs de BDR adicionados',
    },
    loading: {
      default: 'Carregando...',
      waitAMoment: 'Aguarde alguns instantes...',
      validating: 'Validando, aguarde alguns instantes...',
    },
    success: {
      fileValidate: 'Arquivo validado!',
      priorityUpdated: 'Prioridade atualizada',
      validTaskCategory: 'Categoria de tarefa válida selecionada.',
    },
    error: {
      default: 'Ooops! Algo deu errado',
      request: 'Ocorreu um erro com sua requisição. Tente novamente mais tarde.',
      notFound: 'Mmm... Parece que esta página sumiu',
      notFoundSuggestion: 'Parece que esta página não foi encontrada ou não existe.',
      userWithoutGroups: 'Seu usuário não tem grupos permitidos',
      userWithoutVendorId: 'Seu usuário não tem um ID da empresa',
      userWithoutCountry: 'Seu usuário não tem um País',
      userWithoutCountryAndVendor: 'Seu usuário não tem um País e o ID da empresa',
      userWithoutProfile:
        'Ocorreu um erro ao solicitar os dados do seu perfil. Verifique se os dados estão configurados corretamente.',
      userWithoutTaskGroup:
        'Ocorreu um erro ao solicitar os dados dos seus grupos de tarefas. Verifique se os dados estão configurados corretamente.',
      userWithoutTaskTypes:
        'Ocorreu um erro ao solicitar os dados dos tipos de tarefas. Verifique se os dados estão configurados corretamente.',
      userWithoutTaskCategories:
        'Ocorreu um erro ao solicitar os dados das categorias de tarefas. Verifique se os dados estão configurados corretamente.',
      requestUserInfo:
        'Ocorreu um erro com a requisição dos seus dados de usuário. Tente novamente mais tarde.',
      getTaskOptions: 'Desculpe! As opções não foram encontradas. Por favor, tente novamente',
      optimizelyNotLoaded:
        'Desculpe! A conexão com o serviço optimizely não foi realizada com sucesso. Por favor, tente novamente',
      withoutResults: 'Desculpe! Nenhum resultado encontrado',
      searchEmptyList: 'Desculpe! Nenhum resultado encontrado para ',
      searchEmptyListSuggestion: 'Confira se você digitou as palavras corretamente.',
      tasksNotFound: 'Oops! Não há nenhuma tarefa para ser listada.',
      tasksNotFoundSuggestion: 'Tente alterar o filtro selecionado ou criar uma nova tarefa.',
      vendorUndefinedTaskList: 'Selecione um país e empresa disponíveis.',
      emptyFile: 'O arquivo está vazio',
      dropzoneEmpty: 'Nenhum arquivo foi inserido. ',
      dropzoneEmptySuggestion: 'Tente inserir algo!',
      invalidCsvTask: 'Insira um arquivo .CSV válido antes de enviar a tarefa. ',
      fileWrongExtension: 'Extensão de arquivo incorreta',
      fileMoreThanOneColumn: 'O arquivo tem mais de uma coluna',
      fileWrongColumnName: 'Nome da coluna errado',
      multipleAnswer: 'Por favor, adicione pelo menos duas respostas a esta pergunta.',
      duplicatedAnswer:
        'As respostas não podem ser iguais. Por favor, digite um conteúdo diferente para cada resposta.',
      visionJobIdNotValid: 'ERRO: Vision Job ID selecionado inválido',
      selectVisionJobIdValid: 'Por favor, digite ou cole outro Vision Job ID',
      duplicatedBdrId: 'ID de BDR já inserido. Tente outro.',
      invalidBdrId: 'ID de BDR não encontrado. Insira outro.',
      invalidSku: 'SKU não encontrado. Digite outro.',
      someSkuInvalid: 'Alguns SKUs inválidos, baixe o relatório para informações detalhadas',
      allSkuInvalid: 'Todos SKUs inválidos, baixe o relatório para informações detalhadas.',
      operationNotFound: 'Erro ao recuperar informações da operação.',
      subtypeVolumeNotFound:
        'Não é possível criar a tarefa de modelo de portfólio porque esse vendorGroupId não tem o subtype correspondente ao VOLUME criado',
    },
    alert: {
      setTaskPriority: 'Defina a prioridade desta tarefa',
      deprecatedTaskCategory:
        'A categoria selecionada para a tarefa não está mais disponível. Por favor, selecione novamente.',
      reviewUpdates:
        'Uma nova atualização foi enviada pela sua organização. Revise todas as sugestões de novos templates.',
    },
  },
  button: {
    proceed: 'Prosseguir',
    newTask: 'Nova tarefa',
    newPrioritization: 'Enviar nova priorização',
    newRecommendTask: 'Novo template recomendado',
    recommendedUpdates: 'Atualizações recomendadas',
    reviewUpdates: 'Revisar atualizações',
    newSubmission: 'Enviar tarefa',
    openCancellationRequest: 'Abrir solicitação',
    updateCancellationRequest: 'Atualizar solicitação',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    saveChanges: 'Salvar alterações',
    tryAgain: 'Tente novamente',
    backToHome: 'Voltar para página inicial',
    tryAgainTaskOptions: 'clicando aqui.',
    createTask: 'Criar tarefa',
    saveCreateTask: 'Salvar nova tarefa',
    addAudienceCheck: 'Adicionar critério de audiência',
    addEffectivenessCheck: 'Adicionar condições de validação',
    clearSearch: 'Limpar busca',
    clearSearchFilters: 'Limpar filtros',
    clearFilters: 'Limpar tudo',
    viewOnlyTask: 'Ver',
    viewAndEditTask: 'Visualizar e Editar',
    duplicateTask: 'Duplicar',
    deleteTask: 'Deletar',
    deleteSubmission: 'Excluir envio',
    send: 'Enviar',
    downloadModel: 'Baixar modelo',
    browseFiles: 'Navegue no seu dispositivo',
    newQuestion: 'Adicionar nova pergunta',
    tabs: {
      submit: 'Envios',
      priority: 'Prioridades',
    },
    jumpToQuestion: 'Pular para a pergunta',
    endQuestionary: 'Finalizar questionário',
    addNewAnswer: 'Adicionar Nova resposta',
    addVisionJobId: 'Incluir',
    filter: 'Filtros',
    openRequest: 'Abrir solicitação',
    cancelTask: 'Cancelar tarefa',
    downloadCSV: 'Download CSV',
    discard: 'Descartar',
    save: 'Salvar',
    downloadReport: 'Baixar Relatório',
    addOperation: 'Adicionar condição',
    gobacktoedit: 'Voltar para editar',
    goToAudienceBuilder: 'Ir para Construtor de Audiências',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Digite o nome do envio',
        validity: 'Selecione o período de validade',
        drop: 'Arraste para cá um arquivo CSV com os AccountIDs',
      },
      formulaOperation: {
        selectFeature: 'Selecionar recurso',
        selectcombo: 'Selecionar combo',
        selectchallenge: 'Selecionar desafio',
        selectOperator: 'Selecione o operador',
        selectCondition: 'Selecionar condição',
        selectValue: 'Selecione o valor',
        value: 'Exemplo: 5',
        selectPeriod: 'Selecione o período',
        periodNotAvailable: 'Período não disponível',
      },
      formulaOperationEffectiveness: {
        selectFeature: 'Selecionar recurso',
        selectOperator: 'Selecione o operador',
        selectCondition: 'Selecionar condição',
        selectValue: 'Selecione o valor',
        value: 'Exemplo: 5',
        selectPeriod: 'Selecione o período',
        periodNotAvailable: 'Período não disponível',
      },
      task: {
        search: 'Procurar por tarefa',
        name: 'Digite um nome para a tarefa (apenas visível no Admin Portal)',
        instruction: 'Digite uma instrução para a tarefa (será utilizado no BEES•Force pelos RNs)',
        category: 'Selecione uma categoria',
        taskEffective:
          'Digite o texto que será exibido no histórico de tarefas quando a tarefa for válida',
        taskPending:
          'Digite o texto que será exibido no histórico da tarefa enquanto a validação estiver pendente',
        taskNotEffective:
          'Digite o texto que será exibido no histórico de tarefas quando a tarefa for inválida',
        controlTags: 'Insira tags de controle. Exemplo: Campanha, VIP, marcas, etc',
        optionalText: '(Opcional)',
        estimatedTime: 'Exemplo: 5',
        selectValue: 'Selecione um valor',
        taskGroup: 'Selecione o grupo de tarefas',
        simple: {
          audienceCheck: {
            main: 'Se a condição de validação não for adicionada, a tarefa terá sua eficácia ao ser concluída',
          },
          effectivenessCheck: {
            main: 'Se a condição de validação não for adicionada, a tarefa será validada no momento que feita (completada) pelo {profile}.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Adicione os SKUs de Cobertura',
              COMBO: 'Adicionar ID do Combo',
              CHALLENGE: 'Adicionar ID do Desafio',
              VOLUME: 'Adicione os SKUs de Volume',
            },
            minimumQuantity: 'Insira a quantidade mínima',
          },
          selectModelId: 'Selecione um ID de modelo local:',
        },
        survey: {
          inputAnswerFreeText: 'Resposta do RN (Texto Livre)',
          inputAnswerNumeric: 'Resposta do RN (Numérico)',
          questionTitle: 'Digite a pergunta',
          selectValue: 'Selecione um valor',
          optionValue: 'Digite a resposta',
          selectCondition: 'Selecionar condição',
        },
        photo: {
          inputVisionJobId: 'Digite ou cole o Vision Job ID',
          visionJobIdSelected: 'Vision Job ID selecionado',
        },
      },
      taskCancellation: {
        search: 'Pesquisar por nome da solicitação ou motivo',
        criteriaBdrIdsEmpty: 'Exemplo: 018a8dce',
        criteriaBdrIds: 'Inserir ID de BDR',
        inputRequestName: 'Digite um nome para a solicitação.',
        inputRequestReason: 'Digite o motivo do cancelamento',
        requestName: 'Digite o nome da solicitação',
        selectPeriod: 'Selecionar período',
      },
      sponsored: {
        search: 'Selecione uma opção ou digite para buscar',
      },
    },
    label: {
      submission: {
        name: 'Nome do Envio',
        validity: 'Validade',
        audienceFilters: 'Filtros de audiência',
        inclusionList: 'Lista de inclusão',
        exclusionList: 'Lista de exclusão',
      },
      recommendedSubmission: {
        destinationGroup: 'Grupo de destino',
        destinationGroupHint: 'Vendedores qualificados para receber esta tarefa.',
      },
      formulaOperation: {
        selectOperator: {
          equalsToLabel: 'Igual a',
          greaterThanLabel: 'Maior que',
          greaterOrEqualsToLabel: 'Maior ou igual a',
          lessThanLabel: 'Menor que',
          lessOrEqualsToLabel: 'Menor ou igual a',
          label: 'Operador',
        },
        selectCondition: {
          andLabel: 'E',
          orLabel: 'OU',
        },
        selectFeature: 'Recurso',
        selectValue: 'Valor',
        selectPeriod: {
          label: 'Período',
        },
      },
      formulaOperationEffectiveness: {
        selectOperator: {
          equalsToLabel: 'Igual a',
          greaterThanLabel: 'Maior que',
          greaterOrEqualsToLabel: 'Maior ou igual a',
          lessThanLabel: 'Menor que',
          lessOrEqualsToLabel: 'Menor ou igual a',
          label: 'Operador',
        },
        selectCondition: {
          andLabel: 'E',
          orLabel: 'OU',
        },
        combo: 'Combo',
        challenge: 'Desafio',
        selectFeature: 'Recurso',
        products: 'Produtos',
        product: 'Produto',
        selectProducts: 'Selecionar produtos',
        quantityIndicatorIntlText: 'de',
        selectValue: 'Valor',
        selectPeriod: {
          label: 'Período',
        },
      },
      task: {
        sponsor: 'Parceiro',
        name: 'Nome da tarefa',
        instruction: 'Instrução de tarefa',
        category: 'Selecione uma categoria para a tarefa:',
        subCategory: 'Escolha uma subcategoria:',
        taskEffective: 'Texto de tarefa validada',
        taskPending: 'Texto da tarefa pendente',
        taskNotEffective: 'Texto de tarefa inválida',
        globalTask: 'Tarefa Global',
        controlTags: 'Tags de controle',
        estimatedTime: 'Tempo estimado da tarefa',
        frequencySelector: 'Quando esta tarefa deve ser apresentada',
        taskGroup: 'Grupo de tarefas',
        list: {
          selectProfileListTasks: 'Perfil:',
          selectOperation: 'Operação',
          selectOperationTooltip: 'A operação inclui a organização e o vendedor.',
          selectTaskType: {
            label: 'TIPO DE TAREFA:',
            sponsored: 'Patrocinada',
            nonSponsored: 'Não Patrocinada',
          },
        },
        create: {
          selectProfile: 'Quem realizará a tarefa?',
          selectTaskType: 'Que tipo de tarefa você deseja criar?',
        },
        simple: {
          duration: 'Selecione uma estimativa de tempo para completar a task:',
          frequency: 'Selecione uma frequência que esta tarefa deve estar disponível para o RN:',
          audienceCheck: 'Audiência',
          audienceLabel: 'Defina qual é o POCs desta tarefa.',
          audienceInfo: 'Incluir POCs',
          effectivenessCheck: 'Critério de efetividade',
          effectivenessId: {
            CHALLENGE: 'Adicione ID do Desafio',
            COMBO: 'Adicionar ID do Combo',
            MONTHLY_PRODUCT_COVERAGE: 'SKU para validação',
            VOLUME: 'SKU para validação',
          },
          minimumQuantity: 'Quantidade mínima',
          scheduleAndFrequency: {
            title: 'Agendamento e frequência',
            subtitle: 'Definir duração da tarefa, frequência e tempo estimado de conclusão.',
            toggle: {
              title: 'Habilitar desconto de tempo',
              subtitle:
                'Quando habilitado, o tempo gasto nesta tarefa será descontado do tempo total de visita.',
              description: 'Habilitado',
            },
            info: 'Considere o tempo total da visita ao definir o tempo da tarefa. Isso ajuda o algoritmo a distribuir as tarefas de forma eficaz entre os BDRs, garantindo que todos tenham tempo suficiente para concluir suas tarefas.',
          },
          selectModelId: 'Selecione um ID de modelo local:',
        },
        sponsored: 'Tarefa Patrocinada',
        sponsoredPartnerLabel: 'Parceiro',
        sponsoredPartnerParagraph: 'Selecione o parceiro que patrocinará esta tarefa.',

        survey: {
          questionary: {
            questionTitle: 'Pergunta número',
          },
        },
        photo: {
          inputVisionJobId: 'Incluir Vision Job ID',
        },
      },
      taskCancellation: {
        requestName: 'Nome da Solicitação',
        reason: 'Motivo',
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Padrão',
          SURVEY: 'Questionário',
          IMAGE_RECOGNITION: 'Fotografia',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Satisfação do Cliente',
          DIGITAL_EDUCATION: 'Educação Digital',
          EXECUTION: 'Execução',
          VALUE_CREATION: 'Criação de Valor',
          PORTFOLIO_EXPANSION: 'Expansão de Portfólio',
          VOLUME_REVENUE: 'Volume e Receita',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Expansão de Portfólio no Marketplace',
          MARKETPLACE_REVENUE: 'Receita do Marketplace',
          EXECUTION_ABI: 'Execução ABI',
          EXECUTION_MARKETPLACE: 'Execução no Marketplace',
          MI_NEGOCIO: 'Mi Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Desafio e Recompensas',
          ORDER_BEES: 'Pedido no BEES',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Coleta de Dados do Questionário',
          OTHER_TASKS: 'Outras Tarefas',
        },
        subCategory: {
          CHALLENGE: 'Desafio',
          COMBO: 'Combo',
          MONTHLY_PRODUCT_COVERAGE: 'Cobertura Mensal de Produto',
          SHELF: 'Prateleiras',
          COOLER: 'Geladeiras',
          POSTER: 'Pôster',
          VOLUME: 'Volume',
        },
        feature: {
          account_status: 'Status da conta (ativo ou bloqueado)',
          sub_segment: 'Subsegmento (texto)',
          channel: 'Canal (texto)',
          potential: 'Potencial (texto)',
          is_key_account: 'Conta chave (verdadeiro ou falso)',
          has_overprice: 'Preço excessivo (verdadeiro ou falso)',
          has_empty_loan: 'Empréstimo vazio (verdadeiro ou falso)',
          segment: 'Segmento da Poc (texto)',
          days_last_order: 'Dias desde o último pedido do Marketplace (número)',
          qty_skus_mp_l15d: 'SKUs do Marketplace solicitados nos últimos 15 dias (número)',
          qty_skus_mp_l30d: 'SKUs do Marketplace solicitados nos últimos 30 dias (número)',
          qty_skus_mp_l60d: 'SKUs do Marketplace solicitados (número)',
          qty_skus_mp_last_order: 'SKUs do Marketplace no último pedido (número)',
          avg_skus_mp_per_order_l30d:
            'Média de SKUs do Marketplace por pedido nos últimos 30 dias (número)',
          mdn_skus_mp_per_order_l30d:
            'Média de SKUs de mercado por pedido nos últimos 30 dias (número)',
          mkp_revenue_l30d: 'Receita do Marketplace nos últimos 30 dias (número)',
          mkp_revenue_l60d: 'Receita do Marketplace',
          qty_digital_orders_l30d: 'Pedidos digitais nos últimos 30 dias (número)',
          qty_digital_orders_l60d: 'Pedidos digitais',
          non_digital_revenue_l30d: 'Receita não digital nos últimos 30 dias (número)',
          digital_revenue_l30d: 'Receita líquida digital nos últimos 30 dias (número)',
          total_revenue_l30d: 'Receita líquida total nos últimos 30 dias (número)',
        },
        feature_value: {
          true: 'Verdadeiro',
          false: 'Falso',
          active: 'Ativo',
          blocked: 'Bloqueado',
        },
        period: {
          l60d: 'Últimos 60 dias',
          l30d: 'Últimos 30 dias',
          l15d: 'Últimos 15 dias',
          tmth: 'Este mês',
          lmth: 'Último mês',
        },
        simple: {
          frequency: {
            daily: 'A cada visita',
            weekly: 'Semanal',
            everyVisit: 'A cada visita',
            monthly: 'A cada 30 dias',
            once_a_month: 'Uma vez ao mês',
          },
        },
        survey: {
          responseType: {
            numeric: 'Numérico',
            freeText: 'Texto livre',
            checkBox: 'Seleção múltipla',
            radioButton: 'Seleção simples',
            conditional: 'Condicional',
          },
        },
      },
      selectProfile: {
        bdr: 'BDR',
        merchandiser: 'Merchandiser',
        negbdr: 'Negociação BDR',
        business_executive: 'Executivo de Negócios',
        brfbdr: 'BDR BRF',
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'A tarefa padrão é uma ação para os RNs executarem durante a visita ao PDV. Este tipo consiste em um título e uma instrução.',
          SURVEY:
            'O tipo de questionário consiste em várias perguntas para os RNs responderem durante a visita ao PDV. As perguntas podem ter diferentes tipos de respostas, como seleção múltipla, seleção única, texto livre, numérico e sim/não.',
          IMAGE_RECOGNITION:
            'O tipo de tarefa de tirar foto permite que os RNs registrem facilmente os ativos do PDV, como refrigeradores ou materiais de trade marketing, usando a câmera embutida de seus telefones celulares. Este tipo de tarefa também está conectado à inteligência de reconhecimento de imagem (Image Recognition), que permite automatizar a operação de verificação de eficácia.',
        },
        simple: {
          frequency: {
            everyVisit:
              'A cada visita: A tarefa estará disponível em cada visita no PDV. No entanto, esta tarefa pode ser substituída por outra tarefa com prioridade mais alta.',
            monthly:
              'A cada 30 dias: A tarefa estará disponível para o RN em um intervalo de 30 dias entre as visitas.',
          },
        },
      },
    },
    error: {
      requiredField: 'Campo obrigatório',
      minValue: 'Por favor, insira um valor maior ou igual a {value}',
      maxValue: 'Por favor, insira um valor menor ou igual a {value}',
      lessOperator: 'Insira um valor maior do que {value}',
      greaterOperator: 'Insira um valor menor do que {value}',
      greaterZero: 'Por favor, insira um valor maior que 0',
      task: {
        requiredDuration:
          'Por favor, selecione uma opção de estimativa para a tarefa antes de salvar',
        requiredFrequency: 'Por favor, selecione uma frequência antes de salvar',
        requiredCategory: 'Por favor, selecione uma categoria para a tarefa antes de salvar',
        requiredSubCategory: 'Por favor, escolha uma subcategoria antes de salvar',
        requiredEffectivenessIDs: 'Por favor, adicione os IDs antes de salvar',
        requiredMinimumQuantity: 'Por favor, digite a quantidade mínima antes de salvar',
        requiredSponsoredBy: 'Por favor, selecione um parceiro antes de salvar',
        addVisionJobId: 'Para adicionar o ID do Job de Visão selecionado, clique no botão Incluir',
        localModelIdListEmpty: 'Não existem modelIDs válidos configurados para esse vendor group',
      },
    },
    button: {
      changeDefaultMessages: 'Alterar mensagens de validação de tarefa padrão',
    },
    hint: {
      task: {
        inMinutes: '(Em minutos)',
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Digite um valor até 9999',
          },
          inputAnalyticTags:
            'Use vírgula para separar os valores e os espaços serão substituídos por hífen.',
        },
      },
    },
    defaultValues: {
      validEffectiveness: 'Tarefa concluída. Bom trabalho!',
      invalidEffectiveness: 'A tarefa não foi concluída.',
      pendingEffectiveness: 'A validação da tarefa ainda está pendente.',
    },
  },
  submission: {
    noneSubmission: 'Nenhuma ativa',
    status: {
      FINISHED: 'Finalizada',
      SCHEDULED: 'Agendada',
      ACTIVE: 'Ativa',
      CONCLUDED: 'Finalizada',
      INVALID: 'Inválido',
      INVALIDS: 'Inválidos',
      INPROGRESS: 'Processando',
      IN_PROGRESS: 'Processando',
      PENDING: 'Pendente',
      FINALIZED: 'Finalizado',
      SUBMITTED: 'Submetido',
    },
    list: {
      table: {
        taskSubmissions: 'Envios desta tarefa',
        status: 'Status',
        validity: 'Vigência',
      },
      dateFormat: 'dd/MM/yyyy',
      noValidity: 'Sem validade',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Finalizado com erros, {accountFound} de {accountSize} foram processados',
          finishedErrorAccountDuplicated:
            'Finalizado com {accountDuplicated} de {accountSize} duplicados, {accountSubmitted} enviados',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} inválidos, {accountDuplicated} duplicados, {accountSubmitted} enviados',
          invalidItems: 'Erro com arquivo CSV, itens inválidos',
        },
        inProgress: {
          default: 'Carregando CSV: {submissionProgress}',
        },
        success: {
          default: 'CSV processado com sucesso',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'ID da conta',
      accountsJustified: 'Justificado',
      accountsAnswered: 'Respondido',
      accountsNotAnswered: 'Não respondido',
      accountsCancelled: 'Cancelado',
      accountsCreated: 'Criado',
    },
    content: {
      answerYes: 'Sim',
      answerNo: 'Não',
    },
    footer: {
      total: 'Total',
    },
  },
  taskCancellation: {
    status: {
      CANCELLATION_REQUESTED: 'Solicitação de cancelamento',
      PROCESSED: 'Cancelamento concluído',
      NOT_PROCESSED: 'Cancelamento não processado',
      DRAFT: 'Rascunho',
    },
  },
  pagination: {
    pageSize: 'Mostrar {pageSize} itens por página',
  },
  emptyState: {
    title: {
      noRequests: 'Nenhuma solicitação ainda',
    },
    desc: {
      noRequests: 'Suas solicitações futuras serão exibidas aqui.',
    },
  },
  app: {
    taskGroups: { ...taskGroupsPtBR },
  },
  configureOperationModal: {
    title: 'Editar operação',
    description: 'Configure a página de tarefas para refletir o seguinte:',
    cancel: 'Cancelar',
    confirm: 'Salvar',
    titleOrganizationInput: 'Organização',
    subTitleOrganizationInput: 'Pode incluir empresas e país.',
    organizationPlaceholder: 'Selecione uma organização',
    titleSellerInput: 'Vendedor',
    subTitleSellerInput: 'Quem receberá a tarefa.',
    sellerPlaceholder: 'Selecione um vendedor',
    titleSellerViewInput: 'Modo de exibição do vendedor',
    subTitleSellerViewInput: 'Ver operação.',
    sellerViewPlaceholder: 'Selecionar para ver',
    optionalText: '(opcional)',
  },
  drawerComponent: {
    title: 'Selecionar produtos',
    search: 'Pesquisar por nome ou ID do SKU',
    emptyMessage: 'Procure pelos produtos que deseja incluir em sua variável.',
    emptyMessageFiltered:
      'Verifique a ortografia ou tente palavras-chave diferentes. Os SKUs podem já ter sido adicionados.',
    cancelButton: 'Cancelar',
    addSelectedButton: 'Adicionar SKU selecionado',
    noResultsFound: 'Nenhum resultado encontrado',
    nestedTitle: 'SKUs selecionados',
    nestedRemoveAll: 'Remover todos',
    nestedSkusSelected: '{totalSelected} SKUs selecionados',
    finishSelection: 'Concluir seleção',
  },
  drawerComponentTaskList: {
    detailsTabTitle: 'Detalhes',
    detailsCancelBtn: 'Rejeitar',
    detailsUpdateBtn: 'Aceitar',
    recommendedTabTitle: 'Atualizações recomendadas',
    recommendedTabRejectBtn: 'Rejeitar tudo',
    recommendedTabAcceptBtn: 'Aceitar tudo',
    recommendedUpdateType: {
      [RecommendedUpdateType.Create]: 'Novo template',
      [RecommendedUpdateType.Update]: 'Atualizar',
      [RecommendedUpdateType.Delete]: 'Exclusão',
    },
    reviewStatus: {
      [RecommendedUpdateReviewStatus.Accepted]: 'Aceito',
      [RecommendedUpdateReviewStatus.Rejected]: 'Rejeitado',
    },
    reviewedCounter: '{totalRecommendedUpdatesReviewed}/{totalRecommendedUpdates} itens revisados',
    btnShowReviewedItems: 'Mostrar itens revisados',
    btnReview: 'Revisar',
    btnChangeReview: 'Revisar novamente',
  },
  audienceSelect: {
    title: {
      dynamic: 'Selecione filtros',
      static: 'Selecione a lista POCs',
    },
    subTitle: {
      dynamic: 'Defina parâmetros para definir POCs que devem ser impactados por esta tarefa.',
      static:
        'Selecione uma lista POCs predefinida ou carregue uma nova no Construtor de Audiência.',
    },
    fields: {
      label: {
        vendor: 'Vendedor',
      },
      placeholder: {
        vendor: 'Selecione uma opção',
      },
    },
    info: {
      audienceStaticInfo:
        'Você deve ir ao Construtor de Audiência para carregar uma nova lista de POCs. O processamento de uma lista pode levar algumas horas.',
    },
    error: {
      audienceType: 'A seleção é necessária para continuar.',
    },
    btnAddStaticAudience: 'Adicionar audiência',
  },
};

export default ptBR;
