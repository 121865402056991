enum environment {
  DEV = 'development',
  UAT = 'uat',
  SIT = 'qa',
  PROD = 'prod',
}

const env = JSON.parse(localStorage.getItem('portal-config'))?.ENV;
const SERVICES_DOMAIN_US = {
  [environment.PROD]: 'https://services.bees-platform.com',
  [environment.UAT]: 'https://services-uat.bees-platform.dev',
  [environment.SIT]: 'https://services-sit.bees-platform.dev',
  [environment.DEV]: 'https://services-sit.bees-platform.dev',
};

const SERVICES_DOMAIN_EU = {
  [environment.PROD]: 'https://services.eu.mybees-platform.com',
  [environment.UAT]: 'https://services.eu.uat.restricted.mybees-platform.dev',
  [environment.SIT]: 'https://services-sit.bees-platform.dev',
  [environment.DEV]: 'https://services-sit.bees-platform.dev',
};
const isSitEnv = env === environment.SIT;

export function getServiceDomain(isEu = false): string {
  if (process.env.NODE_ENV === 'test') return '';

  if (isEu && !isSitEnv) {
    return SERVICES_DOMAIN_EU[env];
  }
  return SERVICES_DOMAIN_US[env];
}

export const clusterEuVisionConfigurationEndpoint = `${getServiceDomain(
  true
)}/api/vision-configuration`;

export const SERVICE_FRONTLINE_METADATA_CONFIGURATION = `/v1/frontline-metadata-configuration`;
export const SERVICE_FRONTLINE_ACTIVITY_STAGING = `/v1/frontline-activity-staging-service`;
export const SERVICE_FRONTLINE_VENDOR_GROUPS = `/v1/bees-force-vendor-groups-service`;

export const defaultOneVisionConfigurationEndpoint = '/api/vision-configuration';

const SERVICE_TASK_BUSINESS = '/api/tasksbusiness-service';
const SERVICE_TASK_CANCELLATION = '/api/task-cancellation-service';
const SERVICE_USER_CLIENT_CATALOG = '/api/userclientcatalog-service';
const SERVICE_FORCE_FILE = '/api/force-file-service';

export const ENDPOINT_GET_TASK_SURVEY = ({ activityId }: { activityId: string }): string =>
  `activities/${activityId}`;

export const ENDPOINT_GET_VISION_JOB_ID = ({ visionJobId }: { visionJobId: string }): string =>
  `configs/${visionJobId}`;

export const ENDPOINT_CREATE_TASK_SURVEY = 'survey-activities';

export const ENDPOINT_CREATE_TASK_PHOTO = 'image-recognition-activities';

export const ENDPOINT_UPDATE_TASK_SURVEY = ({ activityId }: { activityId: string }): string =>
  `activities/${activityId}`;

export const ENDPOINT_GET_FREQUENCIES = 'frequencies';

export const ENDPOINT_GET_CATEGORIES = 'categories';

export const ENDPOINT_GET_ACTIVITY_TYPES = 'types';

export const ENDPOINT_GET_ACTIVITY_FEATURES = 'features';

export const ENDPOINT_GET_FEATURE_MULTI_SELECT_METADATA = `feature-multi-select-metadata`;

export const ENDPOINT_GET_ACTIVITY_PROFILES = 'profiles';

export const ENDPOINT_ACTIVITIES = 'activities';

export const ENDPOINT_GET_ACTIVITY = ({ activityId }: { activityId: string }): string =>
  `activities/${activityId}`;

export const ENDPOINT_SET_ACTIVITY_PRIORITY = 'activities/priority';

export const ENDPOINT_SET_ACTIVITY_PRIORITY_SYNC = 'activities/priority-sync';

export const ENDPOINT_DELETE_ACTIVITY_TEMPLATE = ({ activityId }: { activityId: string }): string =>
  `activities/${activityId}`;

export const ENDPOINT_STAGING_SERVICE = `staging`;

export const ENDPOINT_GET_SIMPLE_COVERAGE_PRODUCTS_LIST = '/v1/search/query';

//NO CLUSTER VALIDATION
export const ENDPOINT_GET_TASK_DURATIONS = `${SERVICE_TASK_BUSINESS}/config/duration`;
export const ENDPOINT_GET_SUBMISSION_CSV_PROGRESS = ({
  submissionId,
}: {
  submissionId: string;
}): string => `${SERVICE_TASK_BUSINESS}/submissionProgress/${submissionId}`;
export const ENDPOINT_FILE_UPLOAD = `${SERVICE_FORCE_FILE}/upload-stream`;
export const ENDPOINT_GET_PARTNERS = (): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/info-partner`;
export const ENDPOINT_GET_TASK_CANCELLATION_REQUEST = ({
  requestId,
}: {
  requestId: string;
}): string => `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests/${requestId}`;

export const ENDPOINT_GET_TASK_CANCELLATION_REQUESTS = `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests`;
export const ENDPOINT_CREATE_TASK_CANCELLATION_REQUEST = `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests`;
export const ENDPOINT_UPDATE_TASK_CANCELLATION_REQUEST = ({
  requestId,
}: {
  requestId: string;
}): string => `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests/${requestId}`;
export const ENDPOINT_GET_BDR_ALL_INFO = ({ bdrId }: { bdrId: string }): string =>
  `${SERVICE_USER_CLIENT_CATALOG}/bdrs/${bdrId}`;
export const ENDPOINT_GET_CANCELLATION_CURRENT_PERIOD = `${SERVICE_TASK_CANCELLATION}/period`;

// task groups endpoints
export const SERVICE_TASK_GROUP = '/v1/frontline-metadata-configuration';

export const ENDPOINT_CREATE_TASK_GROUP = `${SERVICE_TASK_GROUP}/activity-template-groups`;

export const ENDPOINT_GET_TASK_GROUP_LIST = ({
  vendorGroupId,
}: {
  vendorGroupId: string;
}): string => SERVICE_TASK_GROUP + `/activity-template-groups?${vendorGroupId}`;

export const ENDPOINT_GET_TASK_GROUP = ({ taskGroupId }: { taskGroupId: string }): string =>
  SERVICE_TASK_GROUP + `/activity-template-groups/${taskGroupId}`;

export const ENDPOINT_UPDATE_TASK_GROUP = ({ taskGroupId }: { taskGroupId: string }): string =>
  SERVICE_TASK_GROUP + `/activity-template-groups/${taskGroupId}`;

export const ENDPOINT_PRIORITY_TASK_GROUP =
  SERVICE_TASK_GROUP + `/activity-template-groups/priorities`;

export const ENDPOINT_TASK_GROUPS_LOCAL =
  'http://localhost/v1/frontline-activity-staging-service/groups';

export const ENDPOINT_GET_LOCAL_MODELS = `${SERVICE_TASK_GROUP}/feature-portfolio-model-metadata`;
export const ENDPOINT_GET_SUBTYPES = `${SERVICE_TASK_GROUP}/subtypes`;

export const ENDPOINT_GET_SPONSORS = `${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/sponsors`;
