export const PATH_BASE = '/frontline';
export const PATH_HOME = '/force';

export const PATH_TASK_LIST = `${PATH_BASE}/tasks`;
export const PATH_TASK_GROUPS = `${PATH_BASE}/task-groups`;
export const PATH_TASK_LIST_V1 = 'force/task-management';
export const PATH_CREATE_TASK_SIMPLE = `${PATH_BASE}/tasks/simple/create`;
export const PATH_CREATE_TASK_SURVEY = `${PATH_BASE}/tasks/survey/create`;
export const PATH_CREATE_TASK_PHOTO = `${PATH_BASE}/tasks/photo/create`;
export const PATH_OPERATIONS_PANEL = '/force/operations-panel';

export const PATH_TASK_LIST_PRIORITY = ({ taskGroupId }: { taskGroupId: string }): string =>
  `${PATH_BASE}/tasks?mode=priority&taskGroupId=${taskGroupId}`;
export const PATH_TASK_LIST_SUBMIT = ({ taskGroupId }: { taskGroupId: string }): string =>
  `${PATH_BASE}/tasks?mode=submit&taskGroupId=${taskGroupId}`;
export const PATH_UPDATE_TASK_SIMPLE = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/simple/update/${taskId}`;
export const PATH_UPDATE_TASK_SURVEY = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/survey/update/${taskId}`;
export const PATH_UPDATE_TASK_PHOTO = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/photo/update/${taskId}`;

export const PATH_DUPLICATE_TASK_SIMPLE = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/simple/duplicate/${taskId}`;
export const PATH_DUPLICATE_TASK_SURVEY = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/survey/duplicate/${taskId}`;
export const PATH_DUPLICATE_TASK_PHOTO = ({ taskId }: { taskId: string }): string =>
  `${PATH_BASE}/tasks/photo/duplicate/${taskId}`;

export const PATH_TASK_CANCELLATION_LIST = `${PATH_BASE}/task-cancellation`;
export const PATH_TASK_CANCELLATION_CREATE = `${PATH_BASE}/task-cancellation/create`;
export const PATH_TASK_CANCELLATION_UPDATE = ({ requestId }: { requestId: string }): string =>
  `${PATH_BASE}/task-cancellation/update/${requestId}`;
export const PATH_AUDIENCE_BUILDER = '/audiences?origin=force';
